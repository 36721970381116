
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class CSATBarChart extends Vue {
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public dataGraph!: any;
  @Prop({ default: {} })
  public labelsByIds!: any;
  @Prop({ default: {} })
  public labelsByName!: any;
  @Prop({ default: false })
  public modelId!: any;
  @Prop({ default: "countOfLabel" })
  public keyToShow!: any;
  // public async mounted() {
  //   var myPlot = document.getElementById('plotly-cool');
  //   myPlot?.addEventListener("Click", function (data) {console.log(data)});
  // }

  public keyTranslator =
    {
      meanStars: "Mean stars",
      effectcsat: "CSAT impact",
      effectdsat: "DSAT impact",
      totalCSAT: "CSAT Score",
      totalDSAT: "DSAT Score",
    };

  public handlePlotClick(data) {
    this.focuslabel(this.labelsByName[data.points[0].x].id);
  }

  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }
  get labelAutomaticColorList() {
    const res: string[] = [];
    this.labels.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(k.color)].base));
    });
    return res;
  }
  get predictionCountList() {
    const res: number[] = [];
    const names: string[] = [];
    this.labels.forEach((v, k) => {
      names.push(v.name);
      if (this.dataGraph.category[this.keyToShow][v.name] !== undefined) {
        res.push(this.dataGraph.category[this.keyToShow][v.name].toFixed(2));
      } else {
        res.push(0.00);
      }
    });

    if (this.keyToShow === "totalCSAT") {
      const sumCount: any = Object.values(this.dataGraph.category.countOfLabel)
      .reduce((acc: any, val: any) => acc + val, 0);
      const sumCountCSAT: any = Object.values(this.dataGraph.category.countOfCSAT)
      .reduce((acc: any, val: any) => acc + val, 0);
      const csat = (sumCountCSAT / sumCount) * 100;
      res.push(parseFloat(csat.toFixed(2)));
      names.push("Total CSAT");
    }
    if (this.keyToShow === "totalDSAT") {
      const sumCount: any = Object.values(this.dataGraph.category.countOfLabel)
      .reduce((acc: any, val: any) => acc + val, 0);
      const sumCountCSAT: any = Object.values(this.dataGraph.category.countOfDSAT)
      .reduce((acc: any, val: any) => acc + val, 0);
      const csat = (sumCountCSAT / sumCount) * 100;
      res.push(parseFloat(csat.toFixed(2)));
      names.push("Total DSAT");
    }

    return [res, names];
  }

  get labelAutomaticBarData() {
    return {
      cliponaxis: false,
      x: this.predictionCountList[1],
      y: this.predictionCountList[0],
      type: "bar",
      hoverinfo: 'text+label',
      textposition: "outside",
      text: this.predictionCountList[0],
      colorscale: "Viridis",
      marker: {
        color: this.labelAutomaticColorList,
      },
    };
  }

  get labelBarLayout() {
    return {
      margin: { l: 50, r: 50, t: 50, b: 50 },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      barmode: "stack",
      xaxis: {
        type: 'category',
        automargin: true,
        showgrid: true,
        zeroline: false,
        showline: true,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
      },
      yaxis: {
        title: {
          text: this.keyTranslator[this.keyToShow],
        },
        ticks: "",
        tickformat: ",d",
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
    };
  }

  get labelNames() {
    const mappedNames: string[] = [];
    this.labels.forEach((k) => {
      const filteredLabels = this.labels.filter((label) => label.id === k.id);
      if (filteredLabels.length > 0) {
        mappedNames.push(filteredLabels[0].name);
      }
    });
    return mappedNames;
  }
  @Emit()
  public focuslabel(clickedLabel) {
  }
}
